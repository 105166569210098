import React, { useContext, useState } from "react";
import AppContext from "../context/AppContext";
import SuggestNewDish from "./SuggestNewDish";
import FunBudget from "./FunBudget";
import FeatureSuggestion from "./FeatureSuggestion";
import axios from "axios";
import "./FormScreen.css";
import Logo from "./HungryBudgetLogo.png";

function FormScreen() {
  const appContext = useContext(AppContext);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [NewDish, setNewDish] = useState(false);
  const [showFunBudget, setShowFunBudget] = useState(false);
  const [suggestNewFeature, setSuggestNewFeature] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    appContext.setFormData({ ...appContext.formData, [name]: value });
  };

  const handleInputBudget = (event) => {
    const { name, value } = event.target;

    const maxDigits = 3;
    const input = value.slice(0, maxDigits);

    appContext.setFormData({ ...appContext.formData, [name]: input });

    if (
      name === "minBudget" &&
      parseInt(input) > parseInt(appContext.formData.maxBudget)
    ) {
      appContext.setFormError(
        "Minimum budget cannot be greater than maximum budget"
      );
    } else if (
      name === "maxBudget" &&
      parseInt(input) < parseInt(appContext.formData.minBudget)
    ) {
      appContext.setFormError(
        "Maximum budget cannot be less than minimum budget"
      );
    } else {
      appContext.setFormError("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { budget, course, city, minBudget, maxBudget } = appContext.formData;
    axios
      .post("https://hungrybudget.com/endpoints/registerUserInput.php", {
        budget,
        course,
        city,
        minBudget,
        maxBudget,
      })
      .then((response) => {
        appContext.handleFormSubmit(event);
      })
      .catch((error) => {
        appContext.setFormError("Oopsie! Something went wrong.");
        setShowErrorMessage(true);
      });
  };

  const NewDishScreen = () => {
    setNewDish(!NewDish);
  };

  const getFunBudgetMeals = () => {
    return axios.post(
      "https://hungrybudget.com/endpoints/FunBudget.php",
      appContext.formData
    );
  };

  const requiredFieldsFilled = () => {
    const { course, city, maxBudget } = appContext.formData;
    return course !== "" && city !== "" && maxBudget !== 0 && maxBudget !== "";
  };

  const openFunBudget = () => {
    if (requiredFieldsFilled()) {
      getFunBudgetMeals()
        .then((response) => {
          setShowFunBudget(true);
        })
        .catch((error) => {
          appContext.setFormError(
            "Oopsie! Can't find a dish with this specifications, please change your inputs and try again"
          );
          setShowErrorMessage(true);
        });
    } else {
      alert("Please fill out the required fields before proceeding.");
    }
  };

  const backToFormScreen = () => {
    setShowFunBudget(false);
  };

  return showFunBudget ? (
    <FunBudget backToFormScreen={backToFormScreen} />
  ) : suggestNewFeature ? (
    <FeatureSuggestion NewDishScreen={() => setSuggestNewFeature(false)} />
  ) : NewDish ? (
    <SuggestNewDish NewDishScreen={NewDishScreen} />
  ) : (
    <div>
      <div className='logo-wrapper'>
        <img className='logo' src={Logo} alt='Hungry Budget Logo' />
      </div>

      <form onSubmit={handleSubmit}>
        {appContext.formError && (
          <div className='error-message'>{appContext.formError}</div>
        )}
        <div className='budget-wrapper'>
          <div className='budget-text'>Budget</div>
          <div className='min-max-together'>
            <div className='min'>
              <label htmlFor='minBudget'>Min</label>
              <input
                type='text'
                id='minBudget'
                name='minBudget'
                min='0'
                max='999'
                step='any'
                pattern='\d+(\.\d{1,2})?'
                value={appContext.formData.minBudget}
                onChange={handleInputChange}
                onInput={handleInputBudget}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className='max'>
              <label htmlFor='maxBudget'>Max</label>
              <input
                type='text'
                id='maxBudget'
                name='maxBudget'
                min='0'
                max='999'
                step='any'
                pattern='\d+(\.\d{1,2})?'
                value={appContext.formData.maxBudget}
                onChange={handleInputChange}
                onInput={handleInputBudget}
                onKeyPress={handleKeyPress}
                required
              />
            </div>
          </div>
        </div>
        <div className='locate'>
          <div>
            <label htmlFor='city'>Location:</label>
            <select id='city' name='city' onChange={handleInputChange} required>
              <option value=''></option>
              <option value='provo'>Provo</option>
              <option value='orem'>Orem</option>
            </select>
          </div>
        </div>
        <div className='locate'>
          <div className='meal-wrapper'>
            <label htmlFor='course'>Meal:</label>
            <select
              id='course'
              name='course'
              onChange={handleInputChange}
              required
              className='meal-select'>
              <option value=''></option>
              <option value='breakfast'>Breakfast</option>
              <option value='lunch/dinner'>Lunch/Dinner</option>
              <option value='dessert'>Dessert</option>
            </select>
          </div>
        </div>
        <div className='submits'>
          <button type='submit' className='submit-button'>
            Submit
          </button>
          <button
            type='button'
            className='fun-budget-button'
            onClick={openFunBudget}
            disabled={!requiredFieldsFilled()}>
            Fun Budget
          </button>
        </div>
      </form>

      <h2 className='upcoming-cities'>Next City: Salt Lake City</h2>

      <div className='suggest-new-dish' onClick={NewDishScreen}>
        <div className='new-dish-button'>Suggest New Dish</div>
      </div>
      <div
        className='suggest-new-dish'
        onClick={() => setSuggestNewFeature(true)}>
        <div className='new-dish-button'>Suggest New Features</div>
      </div>

      <a
        className='privacy-policy-dish-suggestion'
        href='http://hungrybudget.com/privacyPolicy.html'>
        Privacy Policy
      </a>
    </div>
  );
}

export default FormScreen;
